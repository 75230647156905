export function saveInLocalStorage(key: string, value: unknown, defaultValue?: string) {
  let valueStr = defaultValue || '';
  try {
    valueStr = JSON.stringify(value);
  } catch (err) {
    console.error('Cannot stringify value in local storage, Error: ' + (err as Error).toString());
  }
  localStorage.setItem(key, valueStr);
}

export function getFromLocalStorage<T>(key: string) {
  if (window === undefined) return null;

  const valueStr = localStorage.getItem(key) || '';
  if (valueStr) {
    // parse local storage cache
    try {
      const cachedVal = JSON.parse(valueStr) || null;
      if (cachedVal) {
        return cachedVal as T;
      }
    } catch (err) {
      console.error('Cannot parse local storage value, Error: ' + (err as Error).toString());
    }
  }
  return null;
}
