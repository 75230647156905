export const SLACK_BOT_TOKEN = process.env.SLACK_BOT_TOKEN!;
export const MONGODB_URI = process.env.MONGODB_URI!;
export const CLIENT_GOOGLE_API_KEY = process.env.NEXT_PUBLIC_CLIENT_GOOGLE_API_KEY!;
export const SERVER_GOOGLE_API_KEY = process.env.SERVER_GOOGLE_API_KEY!;
export const NEXT_PUBLIC_DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!;
export const NEXT_PUBLIC_DATADOG_ENV = process.env.NEXT_PUBLIC_DATADOG_ENV!;
export const NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID =
  process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID!;
export const NEXT_PUBLIC_DATADOG_RUM_ENABLED = process.env.NEXT_PUBLIC_DATADOG_RUM_ENABLED!;
export const NEXT_PUBLIC_COMMIT_SHA = process.env.NEXT_PUBLIC_COMMIT_SHA!;
