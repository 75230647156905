import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export const init = ({
  clientToken,
  env,
  service,
  rumApplicationId,
  rumEnabled,
  version,
}: {
  clientToken: string;
  env: string;
  service: string;
  rumApplicationId: string;
  rumEnabled: boolean;
  version: string;
}) => {
  const commonOptions = {
    clientToken,
    env,
    service,
    site: 'datadoghq.com',
    version,
  };

  datadogLogs.init({
    ...commonOptions,
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
  });

  if (rumEnabled) {
    datadogRum.init({
      ...commonOptions,
      applicationId: rumApplicationId,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackResources: false,
      trackLongTasks: false,
      trackUserInteractions: false,
      enablePrivacyForActionName: false,
      defaultPrivacyLevel: 'allow',
    });
  }
};
