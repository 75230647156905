import { AppRouterCacheProvider } from './AppRouterCacheProvider';
import { CssBaseline } from './CssBaseLine';
import { ThemeProvider } from './theme';

export const MuiProvider = ({ children, theme }: { children: React.ReactNode; theme: any }) => {
  return (
    <>
      <CssBaseline />
      <AppRouterCacheProvider>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </AppRouterCacheProvider>
    </>
  );
};
