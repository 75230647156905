'use client';

import { QueryClient, QueryClientProvider } from '@repo/tanstack-query';
import { createTheme } from '@repo/mui/theme';
import { grey } from '@repo/mui/colors';
import { MuiProvider } from '@repo/mui/MuiProvider';
import { initTranslation } from '../translation/init';
import { ReactNode } from 'react';
import { DatadogInit } from '../components/DatadogInit.tsx';
import { NotificationsProvider } from '@repo/mui/useNotifications';

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    secondary: {
      main: grey[800],
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
});

export default function RootLayout({ children }: { children: ReactNode }): JSX.Element {
  initTranslation();

  return (
    <>
      <DatadogInit />
      <html lang='en'>
        <Head />
        <body>
          <MuiProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <NotificationsProvider>{children}</NotificationsProvider>
            </QueryClientProvider>
          </MuiProvider>
        </body>
      </html>
    </>
  );
}

const Head = () => {
  return (
    <head>
      <title>Roger&apos;s Worker App</title>
      <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
      <meta property='og:title' content="Roger's Worker App" />
      <meta property='og:site_name' content="Roger's Worker App" />
      <meta property='og:url' content='https://worker.rogerapps.com' />
      <meta property='og:type' content='website' />
      <meta
        property='og:image'
        content='https://roger-company-picture.s3.us-east-2.amazonaws.com/website-images/worker-logo.png'
      />
      <link rel='icon' type='image/x-icon' href='favicon.ico' />
      <link
        rel='stylesheet'
        href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
      />
      <link
        href='https://fonts.googleapis.com/css2?family=Handlee&family=Nunito:ital,wght@0,400;0,600;0,800;1,500&display=swap'
        rel='stylesheet'
      />
    </head>
  );
};
